.login {
    margin-top: 120px;

}
.logo_wide{
    text-align: center;
    margin: 48px auto;
    width: 100%;
}

.logo_wide img {
    max-width: 492px;
    width: 95%;
}
.login_container {
    width: 95%;
    display: flex;
    margin: auto;
    justify-content: center;
}
.login_card_container{
    width: 90%;
    max-width: 432px;
    text-align: center;
    position: relative;
}
.login_card {
    margin:0 8px;
    padding:16px;

}
.forget:hover {
    text-decoration: underline;
    cursor: pointer;
}