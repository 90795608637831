.steps_container{
    margin: 120px auto 0;
    max-width: 1024px;
    width: 100%;
    text-align: center;
}

.steps {
    padding:54px;
    width: 100%;
   
}
.steps_list{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
