.council {
    max-width: 810px;
    margin: 120px auto 0;
    width: 100%;
    padding:54px;
    text-align: center;
}
.members {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.member_container{
    min-width: 60%;
    max-width: 70%
}
.member_card {
    margin-bottom: 40px;
}