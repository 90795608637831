.footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 2;
    min-height: 342px;
    background-image: url("/assets/images/Intersection 1.svg");
    background-size: cover;
    margin-top: 10em;

}

.container {
    position: relative;
    max-width: 1064px;
    margin: 0 auto;
    width: 100%;
    align-self: flex-end;
}
.footer_image {
    position: absolute;
    top:-270px;
    right: -150px;
    max-width: 30vw;
}
.logo {
    margin-bottom: 30px;
}
.navlinks{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}
.copyright {
    margin-bottom: 30px;
}
@media only screen and (max-width: 960px) {
    .navlinks{
        flex-direction: column;
        align-items: center;
    }
    .container {
       text-align: center;
    }
    .footer_image{
        display: none;
    }
}