label {
 margin-bottom:.8rem !important;
}
.signup{
    margin-top: 120px;
}
.logo_wide{
    text-align: center;
    margin: 48px auto;
    width: 100%;
}
.logo_wide img {
    max-width: 432px;
    width: 90%;
}
.sign_up_container {
    width: 90%;
    display: flex;
    margin: auto;
    align-items: center;
    flex-direction: column;

}

.sign_up_form_container{
    max-width: 700px;
    text-align: center;
    position: relative;
}

.read_before_signup{
    width: 55%;
    margin:0 8px;
    padding: 30px;
}

.signup_form {
    margin:0 8px;
    padding:20px;

}

.nameSurnameInputs{
    display: flex;
    flex-direction: column;

}

.role_type {
    display: flex;
    justify-content: space-between;
    
    flex-wrap: wrap;
}
.school_type_button {
    min-width: 45%;
}

.form-pad {
    padding: 30px 0;
}



@media only screen and (max-width: 600px) {
    .nameSurnameInputs{
        flex-direction: column;
    }
}

@media only screen and (max-width: 960px) {
    .read_before_signup{
        width: 100%;
    }
    .sign_up_form_container{
        width: 100%;
    }
}

.roleChoserRadio{
    color: white;
    margin-bottom: 20px;
    font-size: 1.3rem;
}