.contact {
    max-width: 810px;
    margin: 120px auto 0;
    width: 100%;
    padding:54px;
    text-align: center;
    text-align: center;
}
.logo {
    object-fit: contain;
    margin: 26px auto;
}
.phone {
    margin: 13px auto;
    line-height: 100%;
}
.address{
    margin-top: 50px;
}