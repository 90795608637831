.layout {
    background: linear-gradient(268deg, rgba(16, 39, 60, 1), rgba(32, 64, 93, 1));
    min-height: 100vh;
    margin:0;
    padding:0;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    width: 100%;
}

.content{
    flex-grow: 1;
    z-index: 1;
    display: flex;
    flex-direction: column;
}
.logo_overlay{
    position: fixed;
    bottom:0;
    left:0;
    max-width: 768px;
    max-height: 700px;
    z-index: 0;
    width: 50%;
}