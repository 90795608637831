.button{
    border-radius: 6px;
    min-height: 30px;
    min-width: 90px;
    font-size: 16px;
    margin:4px;
}
.large {
    min-height: 72px;
    font-size: 30px;
    width: 290px;
}
