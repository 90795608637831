button {
  border-width: 0;
}
.bg_orange {
  background-color: rgb(233, 89, 20);
}

.bg_red {
  background-color: red;
}
.bg_navy {
  background-color: rgba(16, 39, 60, 1);
}

.bg_transparent {
  background-color: transparent;
  border-color: white;
  border-width: 1px;
}
.bg_white {
  background-color: white;
}
.bg_lime {
  background-color: rgba(70, 251, 124, 1);
}
.bg_grey {
  background-color: rgba(103, 131, 157);
}
.red {
  color: red;
}
.white {
  color: white;
}
.orange {
  color: rgb(251, 128, 70);
}
.black {
  color: black;
}
.grey {
  color: rgba(103, 131, 157);
}
.lime {
  color: rgba(70, 251, 124, 1);
}
.smallest {
  font-size: 16px;
  font-weight: 400;
  font-family: Merel;
}
.smaller {
  font-size: 20px;
  font-weight: 400;
  font-family: Tadao;
}
.small {
  font-size: 25px;
  font-family: Tadao;
}
.normal {
  font-size: 30px;
  font-family: Tadao;
}
.big {
  font-size: 40px;
  font-family: Tadao;
}

.bigger {
  font-size: 50px;
}
.biggest {
  font-size:52px;
}

.card {
  border-radius: 25px;
  background-color: rgba(16, 39, 60, 1);
}
.merel {
  font-family: Merel;
}
.tadao {
  font-family: Tadao;
}
.fw_bold {
  font-weight: bold;
}
.fw_normal {
  font-weight: normal;
}
.fw_light {
  font-weight: 300;
}
.inline {
  display: inline;
}

.margin_top_20 {
  margin-top: 20px;
}
@media only screen and (max-width: 960px) {
  .smallest {
    font-size: 12px;
    font-weight: 400;
    font-family: Merel;
  }
  .smaller {
    font-size: 16px;
    font-weight: 400;
    font-family: Tadao;
  }
  .small {
    font-size: 20px;
    font-family: Tadao;
  }
  .normal {
    font-size: 24px;
    font-family: Tadao;
  }
  .big {
    font-size: 27px;
    font-family: Tadao;
  }

  .bigger {
    font-size: 30px;
  }
  .biggest {
    font-size:33px;
  }
}
