.container {
  position: fixed;
  width: 100%;
  top: 0;
  background: linear-gradient(
    270deg,
    rgba(16, 39, 60, 0.9),
    rgba(32, 64, 93, 0.9)
  );
  z-index: 2;
}

.header {
  padding: 32px;
  display: flex;
  justify-content: space-between;
  max-width: 1600px;
  width: 100%;
  margin: auto;
  align-items: baseline;
}
.logo {
  max-height: 50px;
  align-self: center;
}
.toggle {
  display: none;
}

.headerButtonsBox{
  display: flex;
  align-items: center;
  flex-direction: row;
  text-align: center;
}

@media only screen and (max-width: 960px) {
  .container {
    background: linear-gradient(270deg, rgb(16, 39, 60), rgb(32, 64, 93));
  }

  .headerButtonsBox{
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    
  }

  .header {
    flex-direction: column;
    align-items: center;
    padding: 16px;
  }
  .toggle {
    display: inline;
    position: absolute;
    top: 22px;
    right: 30px;
    cursor: pointer;
  }
  .logo {
    max-width: 90%;
  }
  .logo_container {
    align-self: flex-start;
    margin-left: 8px;
  }
  .hidden {
    display: none;
  }

}
