.container {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}
.loading {
    border:5px solid orange;
    border-radius: 50%;
    border-top-width: 0;
    border-bottom-width: 0;
    width: 100px;
    height: 100px;
    animation: rotate .5s linear 0s infinite forwards;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}