
.nav_list {
    max-width: 606px;
    width: 100%;
    list-style: none;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    margin-bottom: 0;
    z-index: 1;
  }
  .nav_list li {
    display: inline;
    font-size: 20px;
  }

  @media only screen and (max-width: 960px) {
    .nav_list {
      flex-direction: column;
      align-items: stretch;
      margin:16px auto;
      text-align: center;
      transition: transform 1s linear 0s;
    }
    .nav_list li {
      padding: 8px;
      border-bottom: 1px solid white;
    }

    .navl_list_footer {
      max-height: 100px;
      flex-wrap: wrap;  
    }
    .navl_list_footer li {
      border-bottom-width: 0;
      padding: 0;
    }
    .hidden{
      display: none;
    }
  }