.floatingMenu {
    display: inline-flex;
    position: fixed;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 45px;
    right: 50px;
    z-index: 100;
}
.arrowContainer {
    width: 50px;
    height: 50px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
}
.social{
    font-size: 1.3em;
    margin-bottom: 1em;
    cursor: pointer;
}