.home {
  flex-grow: 1;
  width: 100%;
  display: block;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sponsor_card {
  background-color: white!important;
  text-align: center;
  display: flex;
  padding: calc(1.4vw + 10px);
  margin-top: 40px;
  flex-direction: row!important;
}

.sponsors{
  width: 100%;
  max-width: 550px;
  margin: 50px auto;
  margin-bottom: 0px;
}

.medya_card {
  background-color: white!important;
  text-align: center;
  display: flex;
  padding: 44px;
  margin-top: 10px;
}
.sponsor_card div {
  flex-grow: 1;
}
.description {
  margin-top: 20px;
  text-align: justify;
}

.desclink {
  font-family: Tadao;
}

.medya {
margin-top: 30px;
}
