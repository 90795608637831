.collapsable {
    background-color: white;
    border-radius: 12px;
    text-align: left;
    padding: 13px 18px;
    width: 100%;
    max-width: 1024px;
    margin: auto;
    line-height: 100%;
}
.collapsable:not(:last-child) {
    margin-bottom: 20px;
}
.title{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    cursor: pointer;
}
.body{
    margin: 1em auto .5em;
    transition: all .5s;

}
.bodyText{
    box-sizing: border-box;
    padding: 16px 0;
}
.hiden {
    height:0!important;
    overflow: hidden;
    margin: 0;
    padding:0;
    opacity: 0;
}