.manage {
    /* margin: 120px auto 0; */
    margin-top: 120px;
    padding: calc(5px + 1vw);
    /* width: 100%; */
    /* max-width: 1600px; */
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
}

.left_step{
    padding: 15px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 1000px;
}
.right   {
    width: 25%;
    flex-grow: 2;
}
.step_one_title{
    display: flex;
    justify-content: space-between;
}
.downloads {
    display: flex;
    justify-content: space-between;
    margin: 50px 0;
}
.downloads a {
    width: 32%;
}

.step_disabled{
    display: flex;
    flex-direction: row!important;
    justify-content: space-between;
    padding: 20px;
    margin-bottom: 20px;
}
.updates {
    background-color: rgba(251, 128, 70, 1)!important;
    padding: 20px;
}
.slide_card{
    padding: 10px;
    text-align: center;
}
.slide_card > *:not(:last-child) {
    margin-bottom: 15px;
}
.category_title {
    border: 1px solid rgba(70, 251, 124, 1);
    border-bottom-width: 0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    width: 250px;
    float: left; 
    height: 50px;
    text-align: center;
    justify-content: center;
}
.category_title_yanci {
    float: right;
    width: calc(100% - 250px);;
    border-bottom: 1px solid rgba(70, 251, 124, 1);
    height: 50px;
}
.resultTextStyle{
        color: goldenrod;
        border-bottom: solid red 2px;
        margin-top: 16px;
        font-size: large;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .loading-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
  }
  
@media only screen and (max-width: 960px) {
    .left, .right {
        width: 100%;
    }
    .downloads{
        flex-direction: column;
    }
    .downloads button {
        width: 100%;
        margin-bottom: 10px;
    }
    .step_one_body{
        margin: 10px 20px;
    }
    .left_step{
        padding: 15px 15px;
    }
    .old_exams {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .banner {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .zeka_banner {
        width: 96%;
    }
    
}