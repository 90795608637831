@font-face {
  font-family: Merel;
  font-weight: 300;
  src: url("/assets/fonts/Merel/Merel-Light.otf");
}
@font-face {
  font-family: Merel;
  font-weight: normal;
  src: url("/assets/fonts/Merel/Merel-Medium.otf");
}
@font-face {
  font-family: Merel;
  font-weight: bold;
  src: url("/assets/fonts/Merel/Merel-Bold.otf");
}

@font-face {
  font-family: Tadao;
  font-weight: normal;
  src: url("/assets/fonts/Tadao/Tadao-Medium.otf");
}
@font-face {
  font-family: Tadao;
  font-weight: bold;
  src: url("/assets/fonts/Tadao/Tadao-Bold.otf");
}

* {
  font-family: Merel;
}
body {
  margin: 0;
  padding: 0;
  font-family: Merel;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
