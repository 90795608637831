.payment{
    width:90%;
    max-width: 810px;
    margin: auto;
    padding: 32px;
    text-align: center;
    margin-top: 120px;
}

.product_container{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-end;
    margin-top: 20px;

}
.button_container {
    /* width: 30%; */
    margin-right: 5px;
}
.check_out_container{
    text-align: left;
    display: flex;
    justify-content: center;
}
@media only screen and (max-width: 960px) {
    .button_container {
        width: 100%;
        margin: 8px;
    }
    .product_container{
        flex-direction: column;
    }
}