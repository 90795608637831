.step_container {
    display: flex;
    flex-direction: row!important;
    justify-content: space-between;
    padding: 20px;
    margin-bottom: 20px;
}

.second {
    width: 100%;
}

.step_title {
    display: flex;
    justify-content: space-between;
}

.step_header {
    padding: 20px;
}

.password {
    margin: 30px auto 60px;
    background-color: white !important;
    width: 350px;
    text-align: center;
    border-radius: 10px;
    padding: 5px;
}

.category_container {
    margin: 31px 50px 0 50px;
}

.category_title {
    border: 1px solid rgb(251, 128, 70);
    border-bottom-width: 0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    width: 250px;
    float: left;
    height: 50px;
    text-align: center;
    justify-content: center;
}
.category_title_bolge {
    border: 1px solid rgb(251, 128, 70);
    border-bottom-width: 0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    width: 400px;
    float: left;
    height: 50px;
    text-align: center;
    justify-content: center;
}

.bolge_centers {
    float: none;
    margin: 50px 0;
}

.bolge_centers a button{
    margin-top: 10px;
    width: 300px;
}

.downloads {
    display: flex;
    justify-content: space-evenly;
    margin: 50px 0;
}
.downloads a {
    width: 280px;
}

.downloads a button {
    width: 100%;
}

.category_title_yanci {
    float: right;
    width: calc(100% - 250px);;
    border-bottom: 1px solid rgb(251, 128, 70);
    height: 50px;
}

.category_title_bolge_yanci {
    float: right;
    width: calc(100% - 400px);;
    border-bottom: 1px solid rgb(251, 128, 70);
    height: 50px;
}
