.input {
    width: 100%;
    border-radius: 12px;
    border-width: 1px;
    min-height: 50px;
    font-size: 24px;
    padding-left: 16px;
}
.select {
    height:48px;
}