.logo_wide img {
  max-width: 90vw;
  margin-top: 15px;
  width: 550px;
  background-color: white;
  padding: 20px;
  border-radius: 15px;
}

.sponsors {
  width: 100%;
  max-width: 550px;
  margin: 50px auto;
  margin-bottom: 0px;
}

.sponsor_card {
  background-color: white !important;
  text-align: center;
  display: flex;
  padding: 44px;
  margin-top: 40px;
  flex-direction: row !important;
}

.description {
  margin-top: 20px;
  text-align: justify;
}

.long_description {
  max-width: 700px;
  margin: 80px auto 80px;
  /* width: 100%; */
  padding: 34px;
  text-align: center;
  background-color: white !important;
  text-align: left;
  font-size: x-large;
}

.bold {
  font-weight: bold;
}

p {
  white-space: pre-wrap;
}

.pageContainer{
  margin-top: 120px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .long_description {
    max-width: 100%;
    width: 100%;
    padding: 14px;
    margin: 20px auto 20px;
  }
  .sponsors {
    width: 70%;
  }
  .sponsor_card {
  padding: 10px;
}

}